import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { httpReauest } from "../utils/httprequest";
import queryString from "query-string";
import ProductCard from "./ProductCard";
import { useTranslation } from "react-i18next";
import Loading from "./Loading";
const CategoryHome = () => {
  const [category, setCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [t] = useTranslation("global");

  async function getCategory() {
    const respopnse = await httpReauest("GET", "/category", {}, {});
    setCategory(respopnse.data.data);
  }

  async function getProducts() {
    setLoading(true);
    const response = await httpReauest(
      "GET",
      `/product?category=${selectedCategory}`,
      {},
      {}
    );
    setProducts(response.data.data.vehicle);
    setLoading(false);
  }
  useEffect(() => {
    getProducts();
    console.log(selectedCategory);
  }, [selectedCategory]);
  useEffect(() => {
    getCategory();
  }, []);
  return (
    <div>
      <div className="border-sec flex flex-col justify-center items-center my-10">
        <span className=" ">
          <h2 className="lg:text-[40px] text-[20px] font-bold text-center ">
            {t("led")}
          </h2>
        </span>
        <h5 className="lg:text-xl mt-2 text-[14px] whitespace-nowrap text-center  text-gray-500">
          {t("led-sec")}
        </h5>
      </div>
      <div className="scroll-cate overflow-x-auto flex gap-7 w-full px-4 py-2">
        <button
          onClick={() => setSelectedCategory("")}
          className={`${
            selectedCategory === "" ? "bg-red-600 text-white " : "border-none"
          } border-red-500 block whitespace-nowrap hover:bg-red-600 px-4 py-1 hover:text-white duration-150 rounded-lg`}
        >
          All
        </button>
        {category.map((e, idx) => (
          <button
            className={`${
              selectedCategory === e._id
                ? "bg-red-600 text-white"
                : "border-none"
            } border-red-500 block whitespace-nowrap hover:bg-red-600 px-4 py-1 hover:text-white duration-150 rounded-lg`}
            onClick={() => setSelectedCategory(e._id)}
          >
            {e.name}
          </button>
        ))}
      </div>
      <div className="grid lg:grid-cols-4 gap-2 lg:gap-3 grid-cols-2 mt-6">
        {loading ? (
          <Loading />
        ) : (
          products
            .slice(0, 8)
            .map((product, idx) => <ProductCard product={product} key={idx} />)
        )}
      </div>
    </div>
  );
};

export default CategoryHome;
