import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa6";
import { toast } from "react-toastify";
import { BASE_URL } from "../config";
import { CartContext } from "../context/CartContext";

const CheckoutForm = () => {
  const [states, setStates] = useState();
  const [cities, setCities] = useState();
  const [previewImg, setPreviewImg] = useState();
  const [img, setImg] = useState();

  const { cart, setCart } = useContext(CartContext);
  const [t, i18n] = useTranslation("global");

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function getData() {
    await fetch("https://iran-locations-api.ir/api/v1/fa/states").then(
      async (res) => {
        const data = await res.json();
        setStates(data);
      }
    );
  }

  useEffect(() => {
    getData();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      address: "",
      state: "",
      city: "",
      postalCode: "",
      nationalCode: "",
    },
    onSubmit: async (values) => {
      if (!img) {
        return toast.error("تصویر چک پرداختی خالی است");
      }

      const keys = Object.keys(values);
      const formdata = new FormData();
      let products = [];
      cart?.map((e) => {
        products.push({ productId: e?._id, count: e.count });
      });

      keys.map((item) => formdata.append(item, values[item]));
      formdata.append("img", img, img.name);
      formdata.append("products", JSON.stringify(products));

      await fetch(BASE_URL + "/order", {
        method: "POST",
        body: formdata,
      }).then(async (dat) => {
        if (dat?.status == 201) {
          toast.success("سفارش شما با موفقیت ثبت شد");
        } else {
          const message = await dat.json();
          toast.error(message?.message);
        }
      });
    },
  });

  async function getCitis() {
    await fetch(
      "https://iran-locations-api.ir/api/v1/fa/cities?state=" +
        formik.values.state
    ).then(async (res) => {
      const data = await res.json();
      setCities(data[0].cities);
    });
  }
  useEffect(() => {
    getCitis();
  }, [formik.values.state]);
  return (
    <form
      onSubmit={formik.handleSubmit}
      dir="rtl"
      class="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0"
    >
      <p class="text-xl font-medium">{t("purchase-information")}</p>
      <p class="text-gray-400">{t("complate-info")}</p>

      <div class="">
        <div className="grid md:grid-cols-2 gap-2">
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              {t('full-name')}
            </label>
            <div class="relative">
              <input
                type="text"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              {t('phone')}
            </label>
            <div class="relative">
              <input
                type="text"
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              {t("national-code")}
            </label>
            <div class="relative">
              <input
                type="text"
                id="nationalCode"
                name="nationalCode"
                value={formik.values.nationalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              {t('postal-code')}
            </label>
            <div class="relative">
              <input
                type="text"
                id="postalCode"
                name="postalCode"
                value={formik.values.postalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>
        </div>
        <div className="grid md:grid-cols-2 gap-2">
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              {t("state")}
            </label>
            <div class="relative">
              <select
                id="state"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              >
                <option></option>
                {states?.map((e) => (
                  <option>{e.name}</option>
                ))}
              </select>
            </div>
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              {t("city")}
            </label>
            <div class="relative">
              <select
                id="city"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.values.state === ""}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              >
                <option></option>
                {cities?.map((e) => (
                  <option>{e.name}</option>
                ))}
              </select>
            </div>
          </span>
          <span className="col-span-full">
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              {t("address")}
            </label>
            <div class="relative">
              <textarea
                type="text"
                id="address"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              {t("img-pay")}
            </label>{" "}
            <label
              htmlFor="img"
              className="relative w-full inline-block border-dashed border rounded-[10px] p-3 h-[200px]  cursor-pointer border-[#444]"
            >
              {previewImg ? (
                <img
                  src={previewImg}
                  className="w-full h-full object-contain"
                  alt=""
                />
              ) : (
                <FaPlus
                  className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                  size={30}
                />
              )}
            </label>
          </span>

          <input
            onChange={(e) => {
              handleImg(e);
            }}
            type="file"
            className="hidden"
            id="img"
            accept="image/png, image/jpg, image/jpeg"
          />
        </div>
      </div>
      <button
        type="submit"
        class="mt-4 mb-8 w-full rounded-md bg-red-500 px-6 py-3 font-medium text-white"
      >
        {t("send-order")}
      </button>
    </form>
  );
};

export default CheckoutForm;
