import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app";
import global_en from "./translation/en/global.json";
import global_ar from "./translation/ar/global.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
