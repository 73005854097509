import React from "react";
import { Link } from "react-router-dom";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Reveal from "./motion/Reveal";
import RevealX from "./motion/RevealX";

const Aboutus = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className="about-p lg:py-20 py-16 lg:px-10 px-5 flex flex-col justify-center items-center  lg:grid grid-cols-5 shadow-lg relative z-20">
      <Link className=" col-span-3 lg:order-2 z-20">
        <RevealX delay={0.5}>
          <video
            className="lg:h-[65vh] rounded-lg w-full  object-cover z-20"
            src="/MVI_8137.mp4"
            controls
            alt=""
          />
        </RevealX>
      </Link>
      <div className=" h-full lg:rounded-l-lg mt-4 lg:mt-0  flex flex-col justify-center items-start gap-5 lg:justify-center px-4 py-2 col-span-2 text-white lg:px-7">
        <RevealX head delay={1}>
          <Link to={"/about"}>
            <h1 className="font-[600]  text-[20px] lg:text-[30px] my-1 z-10 relative">
              {t("head-marketcard")}
            </h1>
          </Link>
          <p className="text-[12px]  lg:text-[16px] text-white z-10 relative">
            {t("about-us-p")}
          </p>
          <div className="grid grid-cols-2 lg:justify-items-start justify-items-center w-full my-3 z-10 relative">
            <Link
              to={"https://wa.me/971542957751"}
              target="_blank"
              className="hidden lg:flex bg-white px-8 py-3 rounded-[50px] text-red-600 justify-center items-center text-xl hover:shadow-xl duration-300"
            >
              {t("chat")}
              <FaWhatsapp className="text-green-500 ml-1" />
            </Link>
            <Link
              to={"tel:+971 542957751"}
              className="text-1xl bg-red-600 text-white rounded-3xl px-[54px] py-[4px] pt-2 lg:hidden"
            >
              <FaPhoneAlt />
            </Link>
            <Link
              to={"https://wa.me/971542957751"}
              target="_blank"
              className="text-2xl bg-blue-500 text-white rounded-3xl px-[54px] py-[4px] lg:hidden"
            >
              <FaWhatsapp />
            </Link>
          </div>
        </RevealX>
      </div>
    </div>
  );
};

export default Aboutus;
