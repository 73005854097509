import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BackButton from "../BackButton";
const ProjectPage = () => {
  const [t] = useTranslation("global");
  return (
    <div className="lg:py-[120px] py-10 lg:px-[150px] px-2 overflow-hidden bg-[#f6f6f6]">
      <BackButton />
      <div className="grid lg:grid-cols-4 gap-5">
        <Link
          to={"/projects/Lexon Commercial Tower"}
          className="border-2 bg-white p-2 rounded-[10px] duration-150 hover:opacity-80"
        >
          <img
            src="/project/lexon (3).jpg"
            className="w-full rounded-[8px]"
            alt="Project Lexon"
          />
          <h5 className="font-[700] text-[24px] px-2 pt-3">{t("project-h")}</h5>
        </Link>
      </div>
    </div>
  );
};

export default ProjectPage;
