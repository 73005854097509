import React from "react";
import { Link } from "react-router-dom";
import { GrClose } from "react-icons/gr";
import { FaArrowRightArrowLeft, FaFilter } from "react-icons/fa6";
import { useEffect } from "react";
import { httpReauest } from "../utils/httprequest";
import { useState } from "react";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

const Category = ({ categoryQuery }) => {
  const [category, setCategory] = useState([]);
  const [t] = useTranslation("global");

  async function getCategory() {
    const respopnse = await httpReauest("GET", "/category", {}, {});
    setCategory(respopnse.data.data);
  }
  useEffect(() => {
    getCategory();
  }, []);
  if (category.length === 0) {
    return "loading...";
  }
  

  return (
    <div>
      <span className=" border-l-4 border-blue-500 text-blue-500 px-3 font-bold hidden lg:block ">
        {t("categories")}
      </span>
      <button className="filter-open  flex justify-start items-center text-gray-800 text-lg px-3 lg:px-0 mt-3 pb-2 border-b-[1px] border-gray-300 lg:border-none lg:w-full cursor-default ">
        <FaFilter className="text-orange-500 lg:hidden" />{" "}
        <span className=" mx-2 lg:hidden">{t("filter")}</span>
        <div className="filter absolute lg:relative left-[-100%] lg:left-0 top-[-3.5%] lg:top-0 flex-col px-5 py-3 flex justify-start items-start z-40 bg-white duration-500  w-[280px] h-full lg:bg-gray-100 lg:w-full lg:z-0 lg:rounded-lg text-[16px]">
          <button className="filter-close text-xl m-3 lg:hidden">
            <GrClose />
          </button>
          {category.map((e, idx) => (
            <Link
              to={{
                search: queryString.stringify({
                  category:e._id
                }),
              }}
              key={idx}
              className="my-2 flex justify-between items-center w-full hover:text-blue-500 duration-300"
            >
              <span className="text-start w-[80%]">{e.name}</span>
              <span>({e.count})</span>
            </Link>
          ))}
        </div>
      </button>
    </div>
  );
};

export default Category;
