import React, { useEffect, useRef } from "react";

// Import Swiper React components
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

// import required modules
import { Autoplay, Pagination, EffectFade } from "swiper/modules";
import { Link } from "react-router-dom";
import RevealX from "./motion/RevealX";
import { useTranslation } from "react-i18next";

const SliderVideo = () => {
  const ref = useRef();
  const ref2 = useRef();

  useEffect(() => {
    ref.current.play();
    ref2.current.play();
  }, []);

  const [t, i18n] = useTranslation("global");
  return (
    <div className="">
      <Swiper
        style={{ direction: "ltr" }}
        effect="fade"
        autoplay={{
          delay: 6000,
        }}
        modules={[Autoplay, EffectFade]}
        className="w-full"
      >
        <SwiperSlide>
          <div className="relative bg-[#000]">
            <video
              src="/video/1.mp4"
              ref={ref}
              muted
              loop
              playsinline
              className="w-full object-cover h-[95vh] bg-fixed"
            ></video>
            <span className="absolute left-0 top-0 w-full h-full bg-black/60"></span>

            <div className="absolute top-[40%] md:top-[45%] translate-y-[-50%] left-[40px] lg:left-[11%] w-full z-10   text-white">
              <RevealX delay={1}>
                <div className=" grid pe-10 md:grid-cols-2 ">
                  <span>
                    <h2 className="font-bold text-[28px] md:text-[40px]  2xl:text-[50px] pe-5">
                      {t("title-slider-1")}
                    </h2>
                    <Link
                      to={"/products"}
                      className="w-[175px] py-2 rounded-lg  bg-red-600  flex justify-center items-center mt-5"
                    >
                      {t("check-product")}
                    </Link>
                  </span>
                </div>
              </RevealX>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative bg-[#000]">
            <video
              src="/video/2.mp4"
              autoPlay
              ref={ref2}
              muted
              loop
              playsinline
              className="w-full object-cover h-[95vh] bg-fixed"
            ></video>
            <span className="absolute left-0 top-0 w-full h-full bg-black/60"></span>

            <div className="absolute top-[40%] md:top-[45%] translate-y-[-50%]  left-[40px] lg:left-[11%] w-full z-10   text-white">
              <RevealX delay={1}>
                <div className=" grid pe-10 md:grid-cols-2 ">
                  <span>
                    <h2 className="font-bold text-[28px] md:text-[40px]  2xl:text-[50px] pe-5">
                      {t("title-slider-2")}
                    </h2>
                    <Link
                      to={"/service"}
                      className="w-[175px] py-2 rounded-lg  bg-red-600  flex justify-center items-center mt-5"
                    >
                      Services
                    </Link>
                  </span>
                </div>
              </RevealX>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SliderVideo;
