import React from "react";

// Import Swiper React components
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

const Slider = ({src}) => {
  return (
    <div className="">
      <Swiper
        style={{ direction: "ltr" }}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3000,
        }}
        modules={[Pagination, Autoplay]}
        className=""
      >
       {src.map((e)=>(
         <SwiperSlide>
         <div className=" relative">
           <Link to={"/products"}>
             <img
               className="w-full  object-cover m-0"
               src={e}
               alt=""
             />
           </Link>
         </div>
       </SwiperSlide>
       ))}
        
        
      </Swiper>
    </div>
  );
};

export default Slider;
