import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from "./ProductCard";
import { httpReauest } from "../utils/httprequest";
const SliderSimilier = () => {
  const [productId, setProductId] = useState([]);
  async function getId() {
    const response = await httpReauest("GET", `/product/best`, {}, {});
    setProductId(response.data.data);
  }
  useEffect(() => {
    getId();
  }, []);

  return (
    <>
      <Swiper
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          800: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        pagination={{
          clickable: true,
        }}
        className=" "
      >
        {productId?.map((product , idx) => (
          <SwiperSlide>
            <ProductCard product={product} key={idx} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default SliderSimilier;
