import React from "react";
import { useTranslation } from "react-i18next";
import { FaCartShopping } from "react-icons/fa6";

const DashboardPage = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className="py-10 lg:mt-[50px] flex justify-center">
      <div className=" w-full container px-3 lg:px-10">
        <div className="lg:grid-cols-4 grid gap-5">
          <span className="py-7 border rounded-md flex flex-col items-center justify-center gap-1">
            <span className="rounded-full p-3 bg-red-500/80">
              <FaCartShopping size={30} color="white" />
            </span>
            <span className="text-[40px]">0</span>
            <span>{t("registered-orders")}</span>
          </span>
          <span className="p-4 border rounded-md lg:col-span-3">
            <div className="">
              <h2 className="font-bold text-xl">{t("my-Informatiom")}</h2>
              <div className="grid grid-cols-3 my-3">
                <div className=" ">
                  <span className="font-bold">{t("name")}</span>
                  <p className="text-gray-500">amir</p>
                </div>
                <div className=" ">
                  <span className="font-bold">{t("family-name")}</span>
                  <p>naderi</p>
                </div>
                <div className=" ">
                  <span className="font-bold">{t('phone')}</span>
                  <p>09031306013</p>
                </div>
              </div>
            </div>
            <div className="my-10">
              <h2 className="font-bold text-lg">{t("recent-orders")}</h2>
              <table class="bg-gray-100 shadow rounded w-full mt-2 py-2">
                <thead>
                  <tr className="gap-2">
                    <th className="border-b p-2">{t("order")} #</th>
                    <th className="border-b p-2" >{t('date')}</th>
                    <th className="border-b p-2">{t("condition")}</th>
                    <th className="border-b p-2">{t("price")}</th>
                  </tr>
                </thead>
                <tbody className="">
                  <tr className="text-center  ">
                    <td className="p-1 bg-gray-50">asdsadsadad</td>
                    <td className="p-1 bg-gray-50">2000/2/2</td>
                    <td className="p-1 bg-gray-50">done</td>

                    <td className="p-1 bg-gray-50">2000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
