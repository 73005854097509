import React from "react";
import { Link } from "react-router-dom";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Reveal from "./motion/Reveal";
import RevealX from "./motion/RevealX";
import { t } from "i18next";

const ServiceCard = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div
      style={{ backgroundImage: "url(/img/bg1.jpg)" }}
      className=" py-16 px-10 flex flex-col justify-center items-center   shadow-lg bg-no-repeat bg-center bg-cover lg:py-16 relative bg-fixed "
    >
      <div className=" h-full lg:rounded-l-lg  flex flex-col justify-center items-center text-white gap-5 lg:justify-center px-4 py-2 col-span-2  lg:px-7">
        <RevealX delay={0.3}>
          <h1 className="font-[600] text-[25px] lg:text-[40px] my-1 z-10 relative text-center">
            {t("service")}
          </h1>
        </RevealX>

        <RevealX head delay={0.6}>
          <p className="text-[16px] lg:text-[20px] z-10 relative text-center lg:px-10">
          {t("contact-us-p")}
          </p>
        </RevealX>
        <RevealX delay={0.9}>
        <div className="flex justify-center items-center my-3 z-10 relative">
       
            <Link
              to={"/service"}
              className="lg:flex bg-red-600  px-8 py-3 lg:mx-auto mt-4 rounded-[50px]  justify-center items-center text-xl hover:shadow-xl duration-300"
            >
              {t("read")}
            </Link>
         
        </div>
        </RevealX>
      </div>
    </div>
  );
};

export default ServiceCard;
