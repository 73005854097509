import { t } from "i18next";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CartContext } from "../../context/CartContext";
import CheckoutForm from "../CheckoutForm";

const CheckoutPage = () => {
  const { cart, setCart } = useContext(CartContext);
  const [t, i18n] = useTranslation("global");
  return (
    <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 lg:mt-[100px] lg:mx-[150px] mx-4 mt-10">
      <div>
        <div class=" bg-gray-100 shadow rounded px-4 py-4">
          <p class="text-xl text-right  text-gray-900 mb-6">{t("bill")}</p>

          <div class=" border-t border-b py-2">
            <p class="text-sm font-medium text-gray-900 my-2">{cart.name}</p>

            <div class="flex items-center justify-between">
              <p class="text-sm font-medium text-gray-900">{t("price")}</p>
              <p class="font-semibold text-gray-900" dir="rtl">
                {cart?.price}$
              </p>
            </div>
            <div class="flex items-center justify-between mt-2">
              <p class="text-sm font-medium text-gray-900">{t("count")}</p>
              <p class="font-semibold text-gray-900"></p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <CheckoutForm />
      </div>
    </div>
  );
};

export default CheckoutPage;
