import React, { useCallback } from "react";
import Slider from "./Slider";
import ProductCard from "./ProductCard";
import Export from "./Export";
import Aboutus from "./Aboutus";
import Input from "./Input";
import Learnmore from "./Learnmore";
import InputMobile from "./InputMobile";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useState } from "react";
import { httpReauest } from "../utils/httprequest";
import { useEffect } from "react";
import Mobileslider from "./Mobileslider";
import Reveal from "./motion/Reveal";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import Footer from "./Footer";
import ServiceCard from "./ServiceCard";
import CategoryHome from "./CategoryHome";
import SliderVideo from "./SliderVideo";
import ProductCardHome from "./ProductCardHome";
const Home = () => {
  const [t] = useTranslation("global");
  const [topProduct, setTopProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setshow] = useState(false);
  async function getData() {
    const response = await httpReauest("GET", "/product/best", {}, {});
    setTopProduct(response.data.data);
  }
  useEffect(() => {
    setLoading(true);
    getData();
    setLoading(false);
    setTimeout(() => {
      setshow(true);
    }, 4000);
  }, []);
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);

    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);
  return (
    <>
      <div className="  ">
        <Particles
          className={
            show ? "opacity-100 duration-200" : "opacity-0 duration-200"
          }
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            background: {
              color: {
                value: "#f4f4f4",
              },
            },
            fpsLimit: 80,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: false,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 1200,
                  duration: 0.2,
                },
              },
            },
            particles: {
              color: {
                value: "#000",
              },
              links: {
                color: "#999999",
                distance: 150,
                enable: true,
                opacity: 0.2,
                width: 1,
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 2,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 1500,
                },
                value: 100,
              },
              opacity: {
                value: 0.2,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 1, max: 5 },
              },
            },
            detectRetina: true,
          }}
        />
        <SliderVideo />
        <div className="px-0 lg:px-0 relative z-30">
          <Aboutus />
        </div>
        <span className="border-sec flex justify-center items-center text-[20px] px-2 lg:px-10 pb-2 xl:mx-[150px] font-bold my-12 lg:text-[40px] relative z-20">
          {t("recommend")}
        </span>
        <div className="grid lg:grid-cols-4 grid-cols-2 gap-2 lg:gap-3 px-2 lg:px-10 xl:px-[150px] relative z-20">
          {loading
            ? "Loading..."
            : topProduct.map((product, idx) => (
                <Reveal>
                  <ProductCardHome product={product} key={idx} />
                </Reveal>
              ))}
        </div>
        <div className="hidden lg:block px-2 lg:px-0 mt-[76px]">
          <Reveal>
            <Slider src={["/slider/1.png"]} />
          </Reveal>
        </div>
        <div className=" lg:hidden mt-5">
          <Mobileslider src={["/slider/1.png"]} />
        </div>

        <div className=" relative z-20 px-2 lg:px-10 xl:px-[150px] ">
          <CategoryHome />
        </div>
        <div className=" mt-16 z-40 relative">
          <Export />
        </div>
        <div className=" lg:hidden">
          <Mobileslider src={["/slider/2.png","/slider/3.png"]} />
        </div>
        <div className="hidden lg:block px-2 lg:px-0 ">
          <Reveal>
            <Slider src={["/slider/2.png","/slider/3.png"]} />
          </Reveal>
        </div>
        <div className=" relative z-20">
          <ServiceCard />
        </div>

        <div
          style={{ backgroundImage: "url(/banner_service.webp)" }}
          className="px-2 lg:px-24  xl:px-[200px]  relative z-20 bg-no-repeat bg-right"
        >
          <Input />
        </div>
      </div>
    </>
  );
};

export default Home;
