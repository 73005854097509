import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineLocalPostOffice } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { CDN_BASE_URL } from "../config";
const ProductCardHome = ({ product }) => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className=" relative  lg:h-[280px] bg-white rounded-lg duration-300 p-3 overflow-hidden">
      <Link to={`/products/${product?._id}`}>
        <span className="absolute left-0 top-0 bg-[#000]/50 flex justify-center items-center w-full h-full text-white font-[600]">
          <p className=" text-center px-6 line-clamp-1 text-[14px] lg:text-[22px]">
            {product.name}
          </p>
        </span>
        <img
          className=" w-full  rounded-t-lg object-contain px-2 h-full"
          src={CDN_BASE_URL + product?.img}
          alt=""
        />
      </Link>
      {/* <div className="p-3 text-[10px] lg:text-[14px]">
        <p className=" absolute bottom-[55px] left-2 right-2 bg-white z-10">{product?.name} </p>
        <p className="p-product">{}</p>
        <button
          onClick={() => {
            window?.Goftino.open();
          }}
          to={`/products`}
          className=" absolute bottom-1 my-2 border-[1px] border-red-600 rounded-lg left-2 right-2 py-2 flex justify-center items-center text-red-600 hover:text-white hover:bg-red-600 duration-300"
        >
          <MdOutlineLocalPostOffice className="text-[20px] mx-1 " />
          <span className="mx-1 whitespace-nowrap">{t("get-price")}</span>
        </button>
      </div> */}
    </div>
  );
};

export default ProductCardHome;
