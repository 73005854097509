import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

const Service = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <div className="lg:mt-[40px] mt-6">
        <img
          className="w-full lg:h-[60vh] object-cover  "
          src="/service.jpg"
          alt=""
        />
      </div>
      <div className="gap-5 flex flex-col lg:text-[17px] lg:px-[150px]  mt-10">
        <div className="flex flex-col gap-5 items-center">
          <h1 className="  border-b-2 border-blue-500 text-3xl pb-2 uppercase">
            {t("what-we-do")}
          </h1>
          <div className="grid lg:grid-cols-2 grid-cols-1 justify-items-center lg:gap-[70px] gap-8 my-3 px-3 ">
            <span className="flex flex-col items-start justify-center order-2 lg:order-1">
              <h5 className="font-bold text-lg uppercase">AI Solution </h5>
              <p className=" py-3">
                Our AI solutions leverage the latest advancements in artificial
                intelligence to help businesses streamline operations, enhance
                customer experiences, and drive innovation. From predictive
                analytics to intelligent automation, we provide customized
                AI-powered tools to meet your unique needs.
              </p>
            </span>
            <img className="w-full order-1 lg:order-2" src="/ai.jpg" alt="" />
            <img className="w-full order-3 " src="/retail.jpg" alt="" />
            <span className="flex flex-col items-start justify-center order-4">
              <h5 className="font-bold text-lg uppercase">RETAIL SOLUTION </h5>
              <p className=" py-3">
                Our retail solutions are designed to optimize every aspect of
                your store operations. From smart shelving and inventory
                management to personalized customer engagement, we offer a
                comprehensive suite of technologies to elevate your in-store
                experience and boost sales.
              </p>
            </span>

            <span className="flex flex-col items-start justify-center order-6 lg:order-5">
              <h5 className="font-bold text-lg uppercase">ROBOTS </h5>
              <p className=" py-3">
                Robotics is a core part of our offerings. We provide
                state-of-the-art robotic systems for a wide range of
                applications, including warehouse automation, assembly lines,
                and customer service. Our robots are built for efficiency,
                precision, and seamless integration with your existing
                infrastructure.
              </p>
            </span>
            <img className="w-full order-5 lg:order-6" src="/robots.jpg" alt="" />
            <img className="w-full order-7" src="/marketing.jpg" alt="" />
            <span className="flex flex-col items-start justify-center order-8">
              <h5 className="font-bold text-lg uppercase">MARKETING CONSULTANCY </h5>
              <p className=" py-3">
                Our marketing consultancy services empower you to stay ahead of
                the curve. We offer strategic guidance on digital marketing,
                content creation, social media engagement, and targeted
                advertising to help you reach and convert your ideal customers.
              </p>
            </span>
            <span className="flex flex-col items-start justify-center order-10 lg:order-9">
              <h5 className="font-bold text-lg uppercase">EXHIBITION </h5>
              <p className=" py-3">
                Elevate your brand's presence at exhibitions and trade shows
                with our cutting-edge exhibition solutions. From eye-catching
                display designs to interactive experiences, we'll work with you
                to create a memorable and impactful showcase that attracts and
                engages your audience.
              </p>
            </span>
            <img className="w-full order-9 lg:order-10" src="/exhibition.jpg" alt="" />
            <img className="w-full order-11" src="/smarthome.jpg" alt="" />
            <span className="flex flex-col items-start justify-center order-12">
              <h5 className="font-bold text-lg uppercase">SMART HOME </h5>
              <p className=" py-3"> 
                Transform your customers' homes into intelligent, interconnected
                spaces with our smart home technologies. Our solutions encompass
                smart appliances, energy management systems, security systems,
                and intuitive control interfaces for a truly seamless and
                efficient living experience.
              </p>
            </span>

            <span className="flex flex-col items-start justify-center order-[14] lg:order-[13] ">
              <h5 className="font-bold text-lg uppercase">SMART COMMERCIAL DISPLAY </h5>
              <p className=" py-3">
                Enhance your business environments with our smart commercial
                display solutions. Featuring advanced digital signage,
                interactive kiosks, and data-driven analytics, our displays help
                you captivate your audience, improve customer engagement, and
                drive operational efficiency.
              </p>
            </span>
            <img className="w-full order-[13] lg:order-[14] " src="/smartcommercial.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
