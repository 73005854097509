import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { httpReauest } from "../utils/httprequest";
import queryString from "query-string";
import ProductCard from "./ProductCard";
import { useTranslation } from "react-i18next";
import { CDN_BASE_URL } from "../config";
import Loading from "./Loading";
const CategoryHomeHeader = ({ small }) => {
  const [category, setCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [t] = useTranslation("global");

  async function getCategory() {
    const respopnse = await httpReauest("GET", "/category", {}, {});
    setCategory(respopnse.data.data);
  }

  async function getProducts() {
    setLoading(true);
    const response = await httpReauest(
      "GET",
      `/product?category=${selectedCategory}`,
      {},
      {}
    );
    setProducts(response.data.data.vehicle);
    setLoading(false);
  }
  useEffect(() => {
    getProducts();
    console.log(selectedCategory);
  }, [selectedCategory]);
  useEffect(() => {
    getCategory();
  }, []);
  return (
    <div className="w-full">
      {!small && (
        <div className="border-sec flex flex-col justify-center items-center my-10">
          <span className=" ">
            <h2 className="lg:text-[40px] text-[20px] font-bold text-center ">
              {t("led")}
            </h2>
          </span>
          <h5 className="lg:text-xl mt-2 text-[15px]  text-gray-500">
            {t("led-sec")}
          </h5>
        </div>
      )}

      <div className="scroll-cate flex-wrap justify-center flex gap-3 w-full px-0 py-2">
        <Link to={"/products"}>
          <button
            onClick={() => setSelectedCategory("")}
            onMouseEnter={() => setSelectedCategory("")}
            className={`${
              selectedCategory === "" ? "text-white " : "text-white/40"
            }  block whitespace-nowrap  px-3 py-1 duration-150 rounded-lg font-[700] outline-none`}
          >
            All
          </button>
        </Link>
        {category?.slice(0, 6).map((e, idx) => (
          <button
            className={`${
              selectedCategory === e._id ? "text-white " : "text-white/40"
            }  block whitespace-nowrap  px-3 py-1 duration-150 rounded-lg font-[700] outline-none`}
            onClick={() => setSelectedCategory(e._id)}
            onMouseEnter={() => setSelectedCategory(e._id)}
          >
            {e.name}
          </button>
        ))}
      </div>
      <div className="flex justify-center gap-4 pt-2">
        {loading ? (
          <Loading />
        ) : (
          products?.slice(0, 7).map((product, idx) => (
            <Link to={"/products/" + product._id}>
              <img
                className="w-[240px] p-5"
                alt={product.name}
                src={CDN_BASE_URL + product.img}
              />
            </Link>
          ))
        )}
      </div>
    </div>
  );
};

export default CategoryHomeHeader;
