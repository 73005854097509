import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RevealX from "./motion/RevealX";

const Export = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className="export grid gap-5 lg:gap-0 lg:grid-cols-2 text-white py-10 lg:py-16 px-4 lg:px-[150px] z-40 ">
      <RevealX head delay={0.4}>
        <div className="text-2xl font-bold lg:ml-5 flex justify-center items-center text-center lg:text-left leading-10">
          {t("p-export")}
        </div>
      </RevealX>
      <RevealX delay={0.8}>
        <div className="flex justify-center items-center">
          <Link
            to={"/projects"}
            className="bg-white  rounded-3xl text-red-600 font-bold hover:shadow-2xl text-center lg:mr-[70px] my-3 px-6 py-3 text-lg duration-200"
          >
            {t("head-export")}
          </Link>
        </div>
      </RevealX>
    </div>
  );
};

export default Export;
