import React from "react";
import { useTranslation } from "react-i18next";
import {
  FaInstagram,
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaLinkedinIn,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Contacts = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <span className="border-l-4 border-blue-500 text-blue-500 px-3 font-bold  ">
        {t("contacts")}
      </span>
      <div className="bg-gray-100 rounded-lg px-3 py-3 my-3 w-full  ">
        <div className="flex flex-col ">
          <div className="flex justify-between items-center my-2">
            <span>{t("contacts")}:</span> <p>{t("office")}</p>
          </div>
          <div className="flex justify-between items-center my-2">
            <span>{t("tell")}:</span> <Link to={"tel:+971 542957751"}>+971 542957751</Link>
          </div>
          <div className="flex justify-between items-center my-2">
            <span>{t("tell")}:</span> <Link to={"tel:+971 545207902"}> +971 545207902</Link>
          </div>
        </div>

        <div className="grid grid-cols-4 w-full justify-items-center  items-start">
          <Link className="bg-blue-900 rounded-full   m-3 text-[20px] p-2 text-white ">
            <FaInstagram />
          </Link>
          <Link className="bg-blue-900 rounded-full  m-3 text-[20px] p-2 text-white">
            <FaFacebookF />
          </Link>
          <Link className="bg-blue-900 rounded-full  m-3 text-[20px] p-2 text-white">
            <FaTwitter />
          </Link>
          <Link className="bg-blue-900 rounded-full  m-3 text-[20px] p-2 text-white">
            <FaYoutube />
          </Link>
        </div>

        <button
          onClick={() => {
            window?.Goftino.open();
          }}
          className="w-full bg-blue-500 rounded-lg text-white py-2 my-2 hover:bg-blue-600 duration-300"
        >
          {t("chatnow")}
        </button>
      </div>
    </div>
  );
};

export default Contacts;
