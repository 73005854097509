import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";

const Mobileslider = ({src}) => {
  return (
    <div>
      <Swiper
        style={{ direction: "ltr" }}
        spaceBetween={30}
        
        autoplay={{
          delay: 3000,
        }}
        modules={[ Autoplay]}
        loop
       
      >
        {src.map((e)=>(
          <SwiperSlide>
          <Link to="/products">
            <img className="w-full object-cover " src={e} alt="" />
          </Link>
        </SwiperSlide>
        ))}
        
      </Swiper>
    </div>
  );
};

export default Mobileslider;
