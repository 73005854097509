import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { httpReauest } from "../utils/httprequest";
import ProductGallery from "./ProductGallery";
import SliderSimilier from "./SliderSimilier";
import { CDN_BASE_URL } from "../config";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import BackButton from "./BackButton";
import { useContext } from "react";
import { CartContext } from "../context/CartContext";

const ProductDetail = () => {
  const [t, i18n] = useTranslation("global");
  const { cart, setCart } = useContext(CartContext);
  const [productId, setProductId] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate()
  async function getId() {
    const response = await httpReauest("GET", `/product/${id}`, {}, {});

    setProductId(response.data.data);
  }

  useEffect(() => {
    getId();
  }, []);

  if (productId.length === 0) {
    return "loading...";
  }
  const desc = productId.desc;
  const image = productId.img;
  const images = productId.images;
  const basicDetail = productId.basic_details;
  const detail = productId.details;
  function handleBuy() {
  setCart(productId)
  navigate('/checkout')
  }
  return (
    <div className="lg:mt-[80px] mt-8 lg:mx-[150px] mx-2 overflow-hidden ">
      <BackButton />
      <div className="lg:grid grid-cols-2 lg:shadow-lg flex flex-col justify-center  ">
        <div className="order-2 lg:order-2 w-full z-10">
          <ProductGallery images={images} img={image} />
        </div>

        <div className="col-span-1 order-1 lg:order-2 lg:p-2">
          <h2 className="font-bold text-lg my-8 text-center">
            {productId.name}
          </h2>
          <table className="w-full flex flex-col gap-3  ">
            <tr className="my-3 hidden lg:inline">
              <th className="text-left">Product Details:</th>
            </tr>
            {basicDetail.map((detail, idx) => (
              <tr className="grid grid-cols-2 w-full">
                <th className="text-left font-normal">{detail.name}:</th>
                <td className="text-left">{detail.value}</td>
              </tr>
            ))}
            {productId.price && (
              <tr className="grid grid-cols-2 w-full">
                <th className="text-left font-normal">{t("price")}:</th>
                <td className="text-left">{productId?.price}</td>
              </tr>
            )}
          </table>
          <div className="w-[100%] lg:grid grid-cols-2 justify-items-center my-8">
            <button
              onClick={handleBuy}
              className="rounded-3xl py-2 w-[100%] lg:w-[90%] bg-blue-500 hover:bg-blue-600 duration-300 text-white"
            >
              {productId.price ? t("get-price") : t("get-price-2")}
            </button>
            
          </div>
        </div>
      </div>
      <div className="my-8 lg:shadow-lg p-2">
        <div className="lg:my-6 my-3 lg:mx-10 text-[12px] lg:text-xl ">
          <h2 className="text-black p-2 border-blue-500 lg:mx-7 font-bold">
            {t("product-video")} :
          </h2>
          {productId?.video && (
            <div className="mb-5 lg:mx-[75px]">
              <video
                src={CDN_BASE_URL + productId?.video}
                className="w-full rounded-[10px] object-cover"
                controls
              />
            </div>
          )}

          <h2 className="text-black p-2 border-blue-500 lg:mx-7 font-bold">
            {t("detail-info")} :
          </h2>
          <table className=" grid lg:my-4   border-[1px] border-gray-300 rounded-lg text-[10px] lg:text-lg lg:mx-[75px] ">
            {detail.map((det, idx) => (
              <tr
                key={idx}
                className="grid grid-cols-2 py-2 px-3 leading-7 odd:bg-gray-100 even:bg-white "
              >
                <th className="text-left">{det.name}:</th>
                <td className="">{det.value}</td>
              </tr>
            ))}
          </table>
        </div>

        <div className="lg:my-10 my-3 lg:mx-10 text-[12px] lg:text-xl ">
          <h2 className="text-black p-2 border-blue-500 lg:mx-7 font-bold">
            {t("detail-desc")} :
          </h2>
          <div
            dangerouslySetInnerHTML={{ __html: desc }}
            className="lg:mx-[75px] lg:text-[14px] text-[11px]"
          ></div>
        </div>

        <div className="lg:my-6 my-3 lg:mx-10 text-[12px] lg:text-xl ">
          <h2 className="text-black p-2 border-blue-500 lg:mx-7 font-bold">
            {t("catalog")} :
          </h2>
          {productId?.catalog && (
            <div className="mb-5 lg:mx-[75px]">
              <img
                src={CDN_BASE_URL + productId?.catalog}
                className="w-full rounded-[10px] object-cover"
              />
            </div>
          )}
        </div>

        <div className="my-10 lg:mx-[75px]  hidden lg:flex flex-col ">
          <p className="text-sm text-gray-600 font-bold my-4">{t("tag")}:</p>
          <div className="flex justify-start items-center text-[12px] text-center">
            <Link className="text-gray-600 bg-gray-100 hover:bg-gray-200 duration-200 rounded-3xl py-1 w-[25%] mx-2">
              {t("tag-1")}
            </Link>
            <Link className="text-gray-600 bg-gray-100 hover:bg-gray-200 duration-200 rounded-3xl py-1 w-[25%] mx-2">
              {t("tag-2")}
            </Link>
            <Link className="text-gray-600 bg-gray-100 hover:bg-gray-200 duration-200 rounded-3xl py-1 w-[25%] mx-2">
              {t("tag-3")}
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full ">
        <span className="font-bold text-[30px] lg:text-[40px] text-center my-10 block ">
          {t("similiar-product")}
        </span>
        <SliderSimilier />
      </div>
    </div>
  );
};

export default ProductDetail;
