import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineLocalPostOffice } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { CDN_BASE_URL } from "../config";
const ProductCard = ({ product }) => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className=" relative w-full h-[300px] lg:h-[410px] flex flex-col justify-center items-center bg-white rounded-lg border-[1px] border-gray-300 hover:shadow-lg duration-300">
      <Link to={`/products/${product?._id}`}>
        <img
          className=" absolute top-1 left-0 right-2 w-full h-[70%] rounded-t-lg object-cover px-2"
          src={CDN_BASE_URL + product?.img}
          alt=""
        />
      </Link>
      <div className="p-3 text-[10px] lg:text-[14px]">
        <p className=" absolute bottom-[55px] left-2 right-2 bg-white z-10">
          {product?.name}{" "}
        </p>
        <p className="p-product">{}</p>
        <button
          onClick={() => {
            window?.Goftino.open();
          }}
          to={`/products`}
          className=" absolute bottom-1 text-[15px] my-2 border-[1px] bg-red-600 gap-2 text-white rounded-lg left-2 right-2 py-2 flex justify-center items-center  hover:text-white hover:bg-red-600 duration-300"
        >
          {product.price ? (
            <span className="mx-1 whitespace-nowrap">
              {t("get-price")} : {product?.price}$
            </span>
          ) : (
            <span className="mx-1 whitespace-nowrap">{t("get-price-2")}</span>
          )}
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
