import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GrClose } from "react-icons/gr";
import { useEffect } from "react";
const Modal = ({ mobile, setBars }) => {
  const [t, i18n] = useTranslation("global");

  const handleChangeLanguge = (e) => {
    i18n.changeLanguage(e);
  };
  return (
    <div className="flex justify-center items-center  w-[100%] h-[100%]">
      <div className="w-[100%] text-black h-[100%] backdrop-blur-lg bg-white backdrop-brightness-50 px-5 py-2 flex flex-col justify-start items-center text-[26px] ">
        <button
          className="text-[50px] m-3 flex justify-start items-start w-full gap-3 "
          onClick={() => setBars(false)}
        >
          <GrClose />
        </button>
        <Link to={"/"} className="my-1" onClick={() => setBars(false)}>
          {t("home")}
        </Link>
        <Link to={"/about"} className="my-1" onClick={() => setBars(false)}>
          {t("aboutus")}
        </Link>
        <Link to={"/service"} className="my-1" onClick={() => setBars(false)}>
          {t("service")}
        </Link>
        <Link to={"/projects"} className="my-1" onClick={() => setBars(false)}>
          {t("projects")}
        </Link>
        <Link to={"/products"} className="my-1" onClick={() => setBars(false)}>
          {t("products")}
        </Link>
      </div>
    </div>
  );
};

export default Modal;
