import React, { useState } from "react";
import { CDN_BASE_URL } from "../config";
import ReactImageGallery from "react-image-gallery";
import ReactImageMagnify from "@blacklab/react-image-magnify";

const ProductGallery = ({ images, img }) => {
  function myRenderItem(propsImage) {
    return (
      <ReactImageMagnify
       
        imageProps={{
          alt: "Wristwatch by Ted Baker London",
          src: propsImage,
          className:"magniprop"
        }}
        magnifyContainerProps={{
          width: 400,
          height: 400,
          className: "magni",
        }}
        magnifiedImageProps={{
          src: propsImage,
          width: 1200,
          height: 1200,
          className:"magniimg"
        }}
        portalProps={{
          id: "protal-magnify",
          horizontalOffset: 10,
          placement : "right-start"
        }}
      />
    );
  }
  const ImageGallery = [
    {
      original: CDN_BASE_URL + img,
      thumbnail: CDN_BASE_URL + img,
      renderItem: () => myRenderItem(CDN_BASE_URL + img),
    },
  ];
  images?.map((item) =>
    ImageGallery.push({
      original: CDN_BASE_URL + item,
      thumbnail: CDN_BASE_URL + item,
      renderItem: () => myRenderItem(CDN_BASE_URL + item),
    })
  );

  return (
    <div className=" relative ">
      {ImageGallery.length > 1 && (
        <ReactImageGallery
          items={ImageGallery}
          
          showNav={false}
        />
      )}
    </div>
  );
};

export default ProductGallery;
