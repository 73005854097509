import React from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(-1)}
      className="  my-2 mb-4 border-[1px] bg-red-600 text-white rounded-lg px-4 py-2 flex justify-center items-center gap-2  hover:text-white hover:bg-red-600 duration-300"
    >
      <FaAngleLeft /> Back
    </button>
  );
};

export default BackButton;
