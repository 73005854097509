import React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import ProductCard from "./ProductCard";
import Category from "./Category";
import Contacts from "./Contacts";
import InputMobile from "./InputMobile";
import Paginate from "./Paginate";
import { useEffect } from "react";
import { httpReauest } from "../utils/httprequest";
import BackButton from "./BackButton";
const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [categoryQuery, setCategoryQuery] = useState({
    category: "",
  });
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const location = useLocation();

  async function getProducts({ category, page }) {
    setLoading(true);
    const search = query.get("keyword");
    const response = await httpReauest(
      "GET",
      `/product?keywords=${search}&category=${category}&page=${page}`,
      {},
      {}
    );
    setProducts(response.data.data.vehicle);
    setPageCount(response.data.data.count);
    setLoading(false);
  }

  useEffect(() => {
    const c = query.get("category");
    const p = page;

    setCategoryQuery({
      category: c,
      page: p,
    });

    getProducts({ category: c ? c : "", page: p ? p : 1 });
  }, [location, page]);

  if (!products) {
    return "loading...";
  }

  return (
    <div className="mt-8 lg:mt-[80px] lg:mx-[150px]">
      <BackButton />
      <div className=" relative flex flex-col lg:grid grid-cols-4 ">
        <div className=" lg:col-span-1 lg:mr-10">
          <Category categoryQuery={categoryQuery} />
          <div className="my-3 w-full sticky top-[64px] hidden lg:inline">
            <Contacts />
          </div>
        </div>
        <div className="grid grid-cols-2 lg:col-span-3 col-span-full lg:grid-cols-3 mb-3 h-fit">
          <div className=" col-span-full grid grid-cols-2 gap-2 lg:gap-3 lg:grid-cols-3 h-fit">
            {loading
              ? "loading..."
              : products.map((product, idx) => (
                  <ProductCard product={product} key={idx} />
                ))}
          </div>
          <div className="w-full col-span-full">
            <Paginate page={page} setPage={setPage} pageCount={pageCount} />
          </div>
        </div>

        <div className="lg:hidden">
          <InputMobile />
        </div>
      </div>
    </div>
  );
};

export default Products;
