import React from "react";
import { useParams } from "react-router-dom";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import BackButton from "../BackButton";

const ProjectDetails = () => {
  const { name } = useParams();

  const images = [
    {
      original: "/project/lexon (1).jpg",
      thumbnail: "/project/lexon (1).jpg",
    },
    {
      original: "/project/lexon (2).jpg",
      thumbnail: "/project/lexon (2).jpg",
    },
    {
      original: "/project/lexon (3).jpg",
      thumbnail: "/project/lexon (3).jpg",
    },
    {
      original: "/project/lexon (4).jpg",
      thumbnail: "/project/lexon (4).jpg",
    },
    {
      original: "/project/lexon (5).jpg",
      thumbnail: "/project/lexon (5).jpg",
    },
    {
      original: "/project/lexon (6).jpg",
      thumbnail: "/project/lexon (6).jpg",
    },
    {
      original: "/project/lexon (7).jpg",
      thumbnail: "/project/lexon (7).jpg",
    },
    {
      original: "/project/lexon (8).jpg",
      thumbnail: "/project/lexon (8).jpg",
    },
    {
      original: "/project/lexon (9).jpg",
      thumbnail: "/project/lexon (9).jpg",
    },
  ];

  return (
    <div className="lg:py-[120px] py-10 lg:px-[150px] px-2 overflow-hidden bg-[#f6f6f6]">
      <BackButton />
      <h1 className="text-[32px] font-[700] text-center">{name}</h1>
      <div className="mt-10">
        <ImageGallery items={images} />
      </div>
    </div>
  );
};

export default ProjectDetails;
