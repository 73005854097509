import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";
import { IoIosMenu } from "react-icons/io";
import { FaAngleDown, FaBars } from "react-icons/fa";
import { httpReauest } from "../utils/httprequest";
import Category from "./Category";
import queryString from "query-string";
import { CiMenuBurger, CiSearch } from "react-icons/ci";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { FaAnglesDown, FaChevronDown } from "react-icons/fa6";
import CategoryHome from "./CategoryHome";
import CategoryHomeHeader from "./CategoryHomeHeader";

const Navbar = () => {
  const [bars, setBars] = useState(false);
  const [t, i18n] = useTranslation("global");
  const [mobile, setMobile] = useState(false);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [show2, setShow2] = useState(false);
  const handleChangeLanguge = (e) => {
    i18n.changeLanguage(e);
  };

  const [category, setCategory] = useState([]);
  async function getCategory() {
    const respopnse = await httpReauest("GET", "/category", {}, {});
    setCategory(respopnse.data.data);
  }
  useEffect(() => {
    getCategory();
  }, []);
  if (category.length === 0) {
    return;
  }

  return (
    <>
      <div
        onMouseLeave={() => setSearch(false)}
        onClick={() => {
          setSearch(false);
        }}
        className="grid grid-cols-3 lg:grid-cols-4 lg:mx-[150px] z-50 py-2 text-white "
      >
        <div className=" flex mx-2 lg:px-0 items-center justify-start col-span-1 ">
          <Link to={"/"}>
            <img className="w-[100px] lg:w-[150px] " src="/t1logo.png" alt="" />
          </Link>
        </div>
        {search && (
          <div
            onClick={(e) => e.stopPropagation()}
            className="flex col-span-full lg:col-span-3 lg:justify-end items-center gap-2 order-2 lg:order-1 w-full px-2"
          >
            <CiSearch className="text-red-600 text-[30px] lg:text-[20px]  duration-200 " />
            <input
              onChange={(e) => setSearchValue(e.target.value)}
              id="search"
              value={searchValue}
              className="lg:w-[50%] rounded-lg w-full h-[35px] text-[15px] lg:text-[15px] lg:h-[30px] text-black border-l-[4px] p-2 border-red-600 outline-none "
              type="text"
              placeholder="Search for what do you looking ..."
            />
            <label htmlFor="search">
              <Link
                to={{
                  pathname: "/products",
                  search: queryString.stringify({
                    keyword: searchValue,
                  }),
                }}
                className="bg-red-600 whitespace-nowrap rounded-2xl px-5 text-white hover:bg-red-600 duration-300 py-[5px] text-[15px] lg:text-[14px] "
              >
                {t("search")}
              </Link>
            </label>
          </div>
        )}
        <div
          className={`${
            search ? "lg:hidden" : "lg:flex"
          } col-span-2 lg:col-span-3  justify-end items-center`}
        >
          <nav className=" lg:flex  items-center justify-center hidden">
            <div className="flex flex-row gap-5 justify-center items-center   text-[15px]  ">
              <Link className=" hover:text-red-600 " to="/">
                {t("home")}
              </Link>

              <div className="z-20 gap-3" id="navbarNav">
                <ul className="flex flex-row gap-5 items-center justify-center ">
                  <li className="">
                    <span className=" enter-div" aria-current="page" to="/">
                      <Link
                        className="chevron-parent hover:text-red-600 flex justify-center items-center py-5"
                        to="/products"
                      >
                        {t("products")}
                        <FaAngleDown className="mt-1 ml-1" size={15} />
                      </Link>

                      <ul className="bg-[#0b0918f1] text-black shadow-lg h-[0px] overflow-hidden opacity-0 flex gap-8 w-full flex-wrap z-0 top-[92%] left-0 py-2 px-3 duration-500 absolute ">
                        <CategoryHomeHeader small />
                      </ul>
                    </span>
                  </li>
                  <li className="">
                    <span className="enter-div relative">
                      <Link
                        className="chevron-parent  hover:text-red-600 flex justify-center items-center "
                        to="/projects"
                      >
                        {t("projects")}
                      </Link>
                    </span>
                  </li>

                  <li className="">
                    <Link className=" hover:text-red-600 " to="/about">
                      {t("aboutus")}
                    </Link>
                  </li>
                  <li className="">
                    <Link className=" hover:text-red-600 " to="/service">
                      {t("service")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="grid lg:flex grid-cols-4 justify-items-end px-3 lg:px-0   items-center w-full lg:w-fit  h-full  text-[16px]">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setSearch(true);
              }}
              className="lg:text-lg lg:m-3 order-2 lg:order-1  text-[25px]   "
            >
              <CiSearch className="hover:text-red-600 duration-200" />
            </button>
            <button
              onClick={(e) => {
                setBars(true);
              }}
              className="lg:text-lg lg:m-3 order-3 lg:order-2 lg:hidden text-[25px] "
            >
              <HiOutlineMenuAlt3 className="" />
            </button>

            <span
              className="relative mx-auto lg:mx-0 col-span-2 enter-div order-1 lg:order-3"
              aria-current="page"
              to="/"
            >
              <span
                onClick={() => (show2 ? setShow2(false) : setShow2(true))}
                className="flex cursor-pointer text-[14px] "
              >
                <span
                  className="flex justify-center items-center gap-1"
                  style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                >
                  {" "}
                  {i18n.language == "en" && "English - EN"}
                  {i18n.language == "fa" && "FA - فارسی "}
                  {i18n.language == "ar" && "AR - العربیه"}
                  <FaAngleDown className="mt-1" size={15} />
                </span>

                {show2 && (
                  <span className={`drop_down`}>
                    <span
                      onClick={() => handleChangeLanguge("en")}
                      className=" cursor-pointer w-full py-2 px-3 whitespace-nowrap"
                    >
                      <span> EN - English </span>
                    </span>
                    <span
                      onClick={() => handleChangeLanguge("fa")}
                      className=" cursor-pointer w-full py-2 px-3 whitespace-nowrap "
                    >
                      <span> FA - فارسی </span>
                    </span>
                    <span
                      onClick={() => handleChangeLanguge("ar")}
                      className=" cursor-pointer w-full py-2 px-3 whitespace-nowrap "
                    >
                      <span>AR - العربیه</span>
                    </span>
                  </span>
                )}
              </span>
            </span>
            <div className="hidden lg:inline mx-2 ">
              <button
                onClick={() => {
                  window?.Goftino.open();
                }}
                className="bg-red-600 rounded-2xl px-12 text-white hover:bg-red-400 duration-300 py-[3px] text-[14px] "
              >
                {t("chatnow")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* بخش قفسه ها */}

      <div
        className={`${
          bars ? "left-0" : "left-full"
        } duration-200 w-full h-full fixed top-0 z-20   text-white`}
      >
        <Modal setBars={setBars} mobile={mobile} />
      </div>
    </>
  );
};

export default Navbar;
