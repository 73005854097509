import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import "./app.css";
import Products from "./components/Products";
import Footer from "./components/Footer";
import ProductDetail from "./components/ProductDetail";
import ProjectPage from "./components/pages/ProjectPage";
import ProjectDetails from "./components/pages/ProjectDetails";
import "react-image-gallery/styles/css/image-gallery.css";
import ContactUs from "./components/ContactUs";
import Service from "./components/Service";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_en from "./translation/en/global.json";
import global_fa from "./translation/fa/global.json";
import global_ar from "./translation/ar/global.json";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./utils/ScrooltoTop";
import { CartContext } from "./context/CartContext";
import { useState } from "react";
import CheckoutPage from "./components/pages/CheckoutPage";
import LoginPage from "./components/pages/LoginPage";
import SignUpPage from "./components/pages/SignUpPage";
import DashboardPage from "./components/pages/DashboardPage";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: { global: global_en },
    fa: { global: global_fa },
    ar: { global: global_ar },
  },
});
const App = () => {
  const [cart, setCart] = useState([]);
  return (
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <ToastContainer position="top-center" />
        <div className="pb-8"></div>
        <div className="fixed top-0 z-50 left-0 right-0 bg-[#111]">
          <Navbar />
        </div>
        <ScrollToTop />
        <CartContext.Provider value={{ cart, setCart }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:id" element={<ProductDetail />} />
            <Route path="/projects" element={<ProjectPage />} />
            <Route path="/projects/:name" element={<ProjectDetails />} />
            <Route path="/about" element={<ContactUs />} />
            <Route path="/service" element={<Service />} />
            <Route path="/checkout" element={<CheckoutPage/>}/>
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          </Routes>
        </CartContext.Provider>
       
        <div className="z-40 relative">
          <Footer />
        </div>
      </BrowserRouter>
    </I18nextProvider>
  );
};

export default App;
